<template>
    <div class="body-container">
        <BannerSection title="Consigue préstamos de dinero rápido<br class='d-none d-lg-block'/>en Ibancar" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/image1.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <p class="mb-0">
                        Cuando tu cuenta está en cero y necesitas cubrir gastos urgentes, los <b>préstamos de dinero
                            rápido</b>
                        ofrecen una solución eficaz. Este financiamiento está diseñado para proporcionar liquidez
                        inmediata con procedimientos simplificados y requisitos flexibles.
                    </p>
                    <p>Descubre cómo funcionan, qué beneficios ofrecen, cuáles son sus requisitos y cómo puedes obtener
                        uno.</p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funcionan?
                        </h2>
                    </span>

                    <p>
                        Los <b>préstamos de dinero rápido</b> se caracterizan por su proceso de aprobación expedito para
                        que los solicitantes accedan a fondos en tiempo récord.
                    </p>
                    <p>Algunas opciones disponibles en el mercado, como Ibancar, incluyen el uso de tu coche como
                        garantía. Esto te permite seguir utilizándolo mientras pagas el préstamo. Esta modalidad es
                        ideal para quienes necesitan dinero urgente, pero no quieren comprometer sus activos a largo
                        plazo.</p>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Ventajas de los préstamos rápidos</h2>
                    </span>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Acceso rápido a fondos:
                            </p>
                            <span> puedes recibir el dinero en tu cuenta en máximo 48 horas.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Proceso simplificado:
                            </p>
                            <span>el trámite es completamente online, sin necesidad de visitas presenciales o papeleo
                                excesivo.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Flexibilidad en requisitos:
                            </p>
                            <span>es apropiado para personas con historial crediticio variado, incluyendo aquellos en
                                Buró de Crédito.</span>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0 bold-text">
                                No requiere grandes garantías:
                            </p>
                            <span>tienes la posibilidad de usar tu coche como garantía sin perder su uso.</span>
                        </li>
                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Requisitos para solicitar este préstamo</h2>
                    </span>
                    <p>Si quieres obtener este tipo de financiamiento, necesitarás:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">Ser mayor de edad.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Resindir en México.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tener una cuenta bancaria operativa.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tener un teléfono móvil y una dirección de e-mail.
                                </span>

                            </p>
                        </li>
                        <li>
                            <p class="mb-0">
                                <span class="">Contar con un auto en propiedad del que seas titular.
                                </span>

                            </p>
                        </li>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="bold-text">Además, deberás contar con los siguientes documentos:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">INE escaneada por ambos lados y una selfie con el documento
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Comprobante de ingresos. Puedes entregar el último recibo de nómina o
                                    estado de cuenta.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Comprobante de domicilio con vigencia máxima de tres meses.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tarjeta de circulación.
                                </span>

                            </p>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0">
                                <span class="">Factura original del auto escaneada
                                </span>

                            </p>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0">
                                <span class="">Fotografías del coche.
                                </span>

                            </p>
                        </li>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/image2.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo obtener tu préstamo en menos de 48 horas?
                        </h2>
                       
                    </span>

                    <p>
                        Para conseguir un préstamo rápidamente, solo necesitas seguir estos pasos:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <span>Selecciona la marca, modelo y año de tu coche.</span>
                        </li>

                        <br>

                        <li>
                            <span>Indica qué kilometraje tiene.</span>
                        </li>
                        <br>

                        <li>
                            <span>Confirma si tienes la factura original del vehículo.</span>
                        </li>
                        <br>
                        <li>
                            <span>Selecciona el botón de “Solicitar préstamo”.</span>
                        </li>
                        <br>

                        <li>
                            <span>El sistema te indicará cuánto pueden prestarte.</span>
                        </li>
                        <br>

                        <li>
                            <span>Completa tus datos personales. </span>
                        </li>
                        <br>

                        <li>
                            <span>Envía la documentación requerida. </span>
                        </li>
                        <br>

                        <li>
                            <span>Si todo está en orden y te aprueban el préstamo, agendamos una visita para comprobar
                                el estado del vehículo y recoger la factura de este. </span>
                        </li>
                        <br>

                        <li>
                            <span>Una vez firmado el contrato y recogida la factura, se hará la transferencia del
                                préstamo a la cuenta que solicites. </span>
                        </li>
                    </ul>

                    <br>
                    <h2 class="bold-text"
                        style="font-size: 20px;">Proceso 100% online para préstamos de dinero rápido</h2>
                    
                    <p>El proceso de solicitud es totalmente online, lo que significa que puedes gestionar todo desde
                        cualquier lugar sin necesidad de desplazarte. Esta conveniencia es clave para quienes necesitan
                        resolver rápidamente sus urgencias financieras.</p>
                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Preguntas frecuentes</h2>
                       
                    </span>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Es posible obtener un préstamo si estoy en
                            Buró de Crédito?</h3>
                        <p style="font-size: 17px;">Sí, aceptamos solicitudes de personas que aparecen en el Buró de
                            Crédito.</p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Cuáles son las consecuencias de no pagar a
                            tiempo?</h3>
                        <p style="font-size: 17px;">Fallar en el pago puede resultar en penalizaciones, afectar tu
                            historial crediticio y perder tu vehículo. En estos casos, lo más recomendable es
                            comunicarse con nosotros para explorar opciones de pago flexibles. </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Qué cantidad puedo solicitar?</h3>
                        <p style="font-size: 17px;">Los montos pueden variar dependiendo de las especificaciones de tu
                            coche. En Ibancar puedes obtener hasta $120,000.</p>
                    </div>
                    <br>

                    <h2 style="font-size: 20px;"
                        class="bold-text">Contacta con nosotros para tu préstamo de dinero rápido</h2>
                    
                    <p>Si estás buscando una opción confiable y rápida para tus necesidades financieras, <a
                           href="https://ibancar.mx/contacto">contacta con
                            nosotros</a> para explorar las opciones de <b>préstamos de dinero rápido</b>.</p>
                    <p>Nuestro equipo está listo para ayudarte a encontrar la mejor solución adaptada a tus necesidades.
                        Además, recuerda que te ofrecemos una ventaja que pocos tienen, en Ibancar no cambiamos la
                        titularidad de tu coche, este seguirá a tu nombre y podrás usarlo como lo venías haciendo
                        durante el plazo del préstamo. </p>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosDeDineroRapido',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos de dinero rápido | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Obtén préstamos de dinero rápido con tu auto en garantía. Sin trámites complicados y con aprobación inmediata. ¡Soluciona tus necesidades financieras hoy mismo!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos de dinero rapido'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-de-dinero-rapido', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>